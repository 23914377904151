<template>
  <v-main class="support">
    <h2 v-if="formSubmitted" class="font-weight-regular text-center ma-10">
      Thank you for your message. We'll get back to you shortly.
    </h2>
    <v-form v-else @submit.prevent="submitFeedback">
      <h2 class="font-weight-light text-center ma-10">
        We welcome your feedback. Report bugs or share suggestions using the
        form below. Your input helps us improve.
      </h2>
      <v-card class="mx-auto pa-12 pb-8 ma-10" rounded="lg" max-width="620">
        <div class="text-subtitle-1 text-medium-emphasis font-weight-bold mb-4">
          Reason for your message
        </div>

        <v-select
          v-model="reason"
          label="Select"
          clearable
          outlined
          :items="feedBackReasons"
          :error-messages="reasonErrors"
          @blur="$v.reason.$touch()"
        ></v-select>

        <div class="text-subtitle-1 text-medium-emphasis font-weight-bold mb-4">
          Your name
        </div>
        <v-text-field
          v-model.trim="name"
          type="text"
          clearable
          outlined
          placeholder="e.g. Joe Bloggs"
          class="mb-2"
          :error-messages="nameErrors"
          @blur="$v.name.$touch()"
        />

        <div class="text-subtitle-1 text-medium-emphasis font-weight-bold mb-4">
          Your email
        </div>
        <v-text-field
          v-model.trim="email"
          type="text"
          clearable
          outlined
          placeholder="e.g. name@mail.com"
          class="mb-2"
          :error-messages="emailErrors"
          @blur="$v.email.$touch()"
          @input="$v.email.$touch()"
        />

        <div class="text-subtitle-1 text-medium-emphasis font-weight-bold mb-4">
          Your message
        </div>
        <v-textarea
          v-model.trim="message"
          no-resize
          outlined
          :error-messages="messageErrors"
          @blur="$v.message.$touch()"
        />

        <v-btn
          type="submit"
          color="primary"
          class="w-100"
          :disabled="$v.$invalid || isLoading"
        >
          Submit your details
        </v-btn>
      </v-card>
    </v-form>
  </v-main>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import { sendFeedback } from "../../services/feedback";

export default {
  name: "Contact",

  mixins: [validationMixin],

  data() {
    return {
      feedBackReasons: ["Feedback, suggestion", "Report a bug/problem"],
      reason: null,
      name: null,
      email: null,
      message: null,
      isLoading: false,
      formSubmitted: false,
    };
  },

  validations: {
    reason: { required },
    name: { required },
    email: { required, email },
    message: { required, maxLengthValue: maxLength(600) },
  },

  computed: {
    reasonErrors() {
      const errors = [];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.required && errors.push("Reason is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message is required");
      !this.$v.message.maxLengthValue &&
        errors.push("Message too long. Maximum length is 600");
      return errors;
    },
  },

  methods: {
    async submitFeedback() {
      this.isLoading = true;

      try {
        await sendFeedback({
          email: this.email,
          reason: this.reason,
          message: this.message,
          name: this.name,
        });

        this.$notify({
          type: "success",
          title: "Feedback form",
          text: "Feedback successfully sent",
        });

        this.formSubmitted = true;
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Feedback form",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
